import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/distribution",
    component: () => import("../views/Distribution.vue"),
  },
  {
    path: "/Services",
    component: () => import("../views/Service.vue"),
  },
  {
    path: "/pricing",
    component: () => import("../views/Pricing.vue"),
  },
  {
    path: "/promotion",
    component: () => import("../views/Promotion.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/sign-up",
    component: () => import("../views/sign-up.vue"),
  },
  {
    path: "/password-forget",
    component: () => import("../views/password-forget.vue"),
  },
  {
    path: "/email-verify",
    component: () => import("../views/email-varify.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Preserve scroll position on back/forward navigation
    } else {
      return { top: 0 }; // Scroll to the top of the page
    }
  },
});

export default router;
