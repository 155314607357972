import Vue from "vue";
import App from "./App.vue";
import router from "./router";
 
import { BootstrapVue } from "bootstrap-vue";
import Scrollspy from "vue2-scrollspy";

var VueScrollTo = require("vue-scrollto");
import VueParticles from "vue-particles"; 
Vue.config.productionTip = false;

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

Vue.use(VueParticles);

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(VueScrollTo);
Vue.use(Scrollspy);  

import "@/assets/scss/style.scss";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
